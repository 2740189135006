import { Store } from "@tanstack/react-store";
import { type MedResult } from "@/types/med-types";

export interface StoreState {
  meds: MedResult[];
  substances: string[];
}

export const store: Store<StoreState> = new Store<StoreState>({
  meds: [],
  substances: [],
});
