"use client";

import { useEffect, type ReactElement } from "react";
import { useStore } from "@tanstack/react-store";
import { useQuery } from "@tanstack/react-query";
import { type MedResult } from "@/types/med-types";
import { store, type StoreState } from "./store";

export default function Page(): ReactElement {
  const { substances } = useStore(store, (state: StoreState) => ({
    substances: state.substances,
  }));
  const { data, isLoading, error } = useQuery<MedResult[]>({
    queryKey: ["meds"],
    queryFn: () => fetch("/api/meds").then((res) => res.json()),
  });

  useEffect(() => {
    if (data) {
      const allSubstances = data.flatMap((med) => med.active_substances);
      const uniqueSubstances = Array.from(new Set(allSubstances));
      store.setState((state) => ({
        ...state,
        meds: data,
        substances: uniqueSubstances,
      }));
    }
  }, [data]);

  return (
    <main>
      <ul>
        {isLoading ? <li>Loading...</li> : null}
        {error ? <li>Error encountered loading meds...</li> : null}
        {substances.map((substance: string) => (
          <li key={substance}>{substance}</li>
        ))}
      </ul>
    </main>
  );
}
